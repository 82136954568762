.project__container {
  display: grid;
  grid-template-rows: repeat(1fr);
}

.project {
  &__item {
    padding: 50px 0;
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    column-gap: 15px;
    background-color: #3986cc;
    color: white;
  }
  &__item__even {
    padding: 50px 0;
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    column-gap: 15px;
    background-color: #48a7ff;
    color: white;
  }

  &__header {
    font-size: 2rem;
  }

  &__description {
    display: inline-block;
    font-size: 1.2rem;
    margin-right: 15vh;
  }

  &__image {
    margin: 30px 50px;
    height: 200px;
    width: 400px;
    object-fit: cover;
  }
}

